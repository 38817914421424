<template>
    <div id="ad-preview-page">
        <div class="header">
            <div class="logo">
                <img
                    :src="$brand.asset_urls.navbar_logo"
                    alt="Agency logo">
            </div>
        </div>
        <div class="ad-preview-generator-body">
            <styled-loader
                v-if="!adPreviewError"
                label="Please wait. We are getting your ads preview ready. It might take a few seconds."
                size="50" />

            <h2
                class="ma-4 gray-text"
                :value="adPreviewError"
                type="error">
                {{ adPreviewError }}
            </h2>
        </div>
    </div>
</template>

<script>
import { OBJECT_TYPE_AD } from '@/helpers/globals';
import { mapActions } from 'vuex';
import StyledLoader from '@/components/globals/StyledLoader.vue';
import channels from '@/components/onboarding/channel-setup/channels';

export default {
    name: 'AdPreviewGenerator',
    title: 'Ad Preview Generator',
    components: {
        StyledLoader,
    },
    data() {
        return {
            statusFromParams: [],
            dealerUuidFromParams: '',
            platformsFromParams: [],
            dealersActiveAds: [],
            dealer: null,
            adPreviewError: '',
        };
    },
    async created() {
        // Read URL params
        const params = this.readUrlParams();

        if(!params) {
            return;
        }

        // Fetch dealer details
        await this.getDealer();

        if (!this.dealer) {
            return;
        }

        // Fetch ads for all platforms
        const platformAds = await this.fetchAdsForAllPlatforms();

        // Build data structure
        const data = await this.buildData(platformAds);

        // Generate preview URL
        const fullUrl = await this.getPreviewUrl(data);

        // Redirect to the final URL
        const urlObj = new URL(fullUrl);

        const finalUrl = urlObj.pathname + urlObj.search;

        //redirect to the url
        await this.$router.push(finalUrl);
    },
    methods: {
        ...mapActions({
            getPreviewUrl: 'playbookManagement/getPreviewUrl',
        }),
        readUrlParams() {
            this.statusFromParams = this.$route.query.status || [];
            this.dealerUuidFromParams = this.$route.query.dealerUuid || '';
            const platforms = this.$route.query.platforms
                ? this.$route.query.platforms.split(',')
                : [];

            if (this.platformsValidation(platforms)) {
                console.log('Invalid platforms in the URL parameters.');
                this.adPreviewError = 'Something went wrong! Please contact support.';
                return false;
            } else {
                this.platformsFromParams = platforms;
            }
            return true;
        },
        async getDealer() {
            try {
                const response = await this.$apiRepository.getDealerByUuid(this.dealerUuidFromParams);
                this.dealer = response.data;
                return response.data.data;
            } catch (error) {
                this.adPreviewError = error.response?.status === 429
                    ? error.response.statusText
                    : 'Something went wrong! Please contact support.';

                console.log(error.response);
            }
        },
        async fetchAdsForAllPlatforms() {
            const adsByPlatform = {};

            for (const platform of this.platformsFromParams) {
                try {
                    const config = {
                        dealerId: this.dealer.id,
                        platform,
                        status: this.statusFromParams.toUpperCase(),
                        pageSize: 1000,
                    };

                    const response = await this.$apiRepository.getDealerPlatformAdsPreview(config);
                    adsByPlatform[platform] = response.data.data;
                } catch (error) {
                    adsByPlatform[platform] = [];
                }
            }

            return adsByPlatform;
        },
        async buildData(platformAds) {
            const data = { dealer: this.dealer };

            for (const [platform, ads] of Object.entries(platformAds)) {
                if (!ads) continue; // Skip platforms with no data
                data[platform] = {
                    [OBJECT_TYPE_AD]: ads.map(({ external_id, campaign_id, ad_set_id }) => ({
                        external_id,
                        campaign_id,
                        ad_set_id,
                    })),
                };
            }

            return data;
        },
        platformsValidation(platforms) {
            const channelsName = channels.map(channel => channel.name.toLowerCase());

            const areAllPlatformsValid = platforms.every(platform => channelsName.includes(platform));

            return !areAllPlatformsValid;
        }
    },
};
</script>

<style lang="scss">
@import "@/assets/scss/playbook-management.scss";
body{
    overflow: visible;
}

.ad-preview-generator-body {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh
}
</style>
